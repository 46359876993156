//
// Application
//

import './fonts/Adieu/Adieu-Bold.woff';
import './fonts/Adieu/Adieu-Bold.woff2';
import './fonts/Adieu/Adieu-Regular.woff';
import './fonts/Adieu/Adieu-Regular.woff2';
import './fonts/Bureau/STKBureau-SansBook.woff2';
import './fonts/Bureau/STKBureau-SansBook.woff2';



// Animate

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
  duration: 1300,
  once: true
});



const Metteson = {
  // Options / globals
  body: null,

  init() {
    this.body = document.body;
    console.log('lol')
  },

  initOnLoad() {
  }
};

var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
    this.classList.toggle("active");

    /* Toggle between hiding and showing the active panel */
    var panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  });
}

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});

document.addEventListener('DOMContentLoaded', () => Metteson.init());
window.addEventListener('load', () => Metteson.initOnLoad());

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
